import { actionTypes } from "./type";

const initialState = {
    activities: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACTIVITIES:
            return {
                ...state,
                activities: action.value,
            }
        default:
            return state;
    }
}

export default reducer;