import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { store } from "./store";
import { silentAuth } from "./utils/auth";

const SessionCheck = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    silentAuth(handleCheckSession);
  }, [])
  const handleCheckSession = () => {
    setLoading(false)
  };

  return !loading && (
    <React.Fragment>{props.children}</React.Fragment>
  );

}

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  //   const store = makeStore();
  return (
    <SessionCheck>
      <Provider store={store}>{element}</Provider>
    </SessionCheck>
  );
};
