import { navigate } from 'gatsby';
import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import ProfileService from '../../services/ProfileService'
import AccountService from '../../services/AccountService'
import { moduleTypes } from '../type';
import { store } from "../../store";
import { actionTypes } from './type';
import { actionTypes as investorQualificationActionTypes } from '../InvestorQualification/type'
import { actionTypes as accountsActionTypes } from '../Accounts/type'
import { actionTypes as appActionTypes } from "../../store/App/type";
import { actionTypes as dialogActionTypes } from "../../store/Dialog/type";
import { logout } from '../../utils/auth'

const profileService = new ProfileService()
const accountService = new AccountService()

function* init() {
    yield takeEvery(actionTypes.GET_PROFILE, initApp);
    yield takeEvery(actionTypes.LOAD_ACCOUNTS_DATA, loadAccounts);
    yield takeLatest(actionTypes.UPDATE_LOGGING_IN, updateStartPage);
}

const profileState = (state) => state[moduleTypes.APP]?.profile;

const authUserState = (state) => state[moduleTypes.APP]?.authUser;

const signUpProcessState = (state) => state[moduleTypes.INVESTOR_QUALIFICATION].activeStep;

export function* createProfile() {
    const profile = yield select(profileState);

    if (profile?.firstName) {
        return;
    }

    const auth = yield select(authUserState);
    const response = yield call(profileService.createProfile, {
        auth0Id: auth.sub, firstName: auth.given_name, lastName: auth.family_name, email: auth.email
    });

    yield put({
        type: actionTypes.UPDATE_PROFILE,
        value: {
            _id: response?._id,
            firstName: response?.firstName,
            lastName: response?.lastName,
            email: response?.email,
            firstTimeLogin: true
        }
    });
}

function* initApp() {

    try {
        yield put({ type: actionTypes.LOAD_START });
        let profileResponse = yield call(profileService.getProfile);

        const { isEmailVerified, signUpProcessState, signUpDisqualificationOfAccreditedState, signUpDisqualificationOfQualifiedState } = profileResponse || {};
        const firstTimeLogin = !profileResponse?.isQualificationStepOneComplete && profileResponse?.isUserCreatingFirstAccount && !signUpProcessState;

        yield put({
            type: appActionTypes.IS_EMAIL_VERIFIED,
            value: isEmailVerified,
        });

        yield put({
            type: actionTypes.UPDATE_PROFILE,
            value: {
                _id: profileResponse?._id,
                firstName: profileResponse?.firstName,
                lastName: profileResponse?.lastName,
                email: profileResponse?.email,
                firstTimeLogin
            }
        });

        const isBypass = false;
        console.log('isEmailVerified', isEmailVerified);
        console.log('isBypass', isBypass);
        if (!isEmailVerified && !isBypass) {
            yield put({
                type: dialogActionTypes.SHOW_DIALOG, value: { show: true, type: 'dialog_verification_code' }
            });
        } else if (signUpProcessState) {
            yield put({
                type: investorQualificationActionTypes.LOAD_INVESTER_QUALIFICATION,
                value: {
                    disqualificationOfAccredited: signUpDisqualificationOfAccreditedState,
                    disqualificationOfQualified: signUpDisqualificationOfQualifiedState,
                    activeStep: signUpProcessState,
                }
            })
        };
        
        if (firstTimeLogin) {
            throw new Error()
        }
        yield loadData(profileResponse);
    } catch (e) {
        console.log(e.message)
    } finally {
        yield put({ type: actionTypes.UPDATE_LOGGING_IN });
    }
}

function* loadData(profileResponse) {

    let accountResponse = yield call(accountService.getAllAccounts, profileResponse?._id);
    if (accountResponse.length === 0) {
        throw new Error()
    };
    const response = accountResponse[accountResponse.length - 1];
    console.log(response);
    let newAccount = {
        type: response?.type,
        _id: response?._id,
        userId: response?.userId,
        contactInfo: {
            addressLineOne: response?.addressLineOne,
            addressLineTwo: response?.addressLineTwo,
            city: response?.city,
            state: response?.state,
            zipcode: response?.zipcode,
            phoneNo: response?.phoneNo,
            extension: response?.extension,
        },
    }
    if (response?.type === "individual" || response?.type === "joint" || response?.type === "sd-ira") {
        newAccount = {
            ...newAccount,
            contactInfo: {
                ...newAccount.contactInfo,
                legalFirstName: response?.legalFirstName || profileResponse?.firstName,
                legalLastName: response?.legalLastName || profileResponse?.lastName,
                qualification: response?.qualification,
                investorEmail: response?.investorEmail,
                mailChecked: response?.mailChecked,
                duplicateChecked: response?.duplicateChecked,
                secondFirstName: response?.secondFirstName,
                secondLastName: response?.secondLastName,
            }
        }
    } else if (response?.type === "ria") {
        newAccount = {
            ...newAccount,
            contactInfo: {
                ...newAccount.contactInfo,
                legalFirstName: response?.legalFirstName,
                legalLastName: response?.legalLastName,
                entityName: response?.entityName,
                mailChecked: response?.mailChecked,
            },
            accountInfo: {
                entityName: response?.entityName,
                entityType: response?.entityType,
                signersTitle: response?.signersTitle,
            }
        }
        if (response?.verifiedBusiness) {
            newAccount['contactInfo'].verifiedBusiness = response?.verifiedBusiness;
        }
    } else {
        newAccount = {
            ...newAccount,
            contactInfo: {
                ...newAccount.contactInfo,
                legalFirstName: response?.legalFirstName,
                legalLastName: response?.legalLastName,
                signersFirstName: response?.signersFirstName,
                signersLastName: response?.signersLastName,
                entityName: response?.entityName,
                taxIdentificationType: response?.taxIdentificationType,
                mailChecked: response?.mailChecked,
                duplicateChecked: response?.duplicateChecked,
            },
            accountInfo: {
                entityName: response?.entityName,
                entityType: response?.entityType,
                signersTitle: response?.signersTitle,
                signersName: response?.signersName,
                signersEmail: response?.signersEmail,
                qualification: response?.qualification,
                taxIdentificationType: response?.taxIdentificationType,
            }
        }
    }
    yield put({
        type: accountsActionTypes.LOAD_ACCOUNTS,
        value: {
            newAccount,
            parentAccounts: accountResponse,
        }
    })
    yield put({
        type: actionTypes.LOAD_SUCCESS
    })
}
function* loadAccounts() {
    try {
        yield put({ type: actionTypes.LOAD_START });
        const profile = yield select(profileState);
        yield loadData(profile);
    } catch (e) {
        console.log(e.message)
    } finally {
        yield put({ type: actionTypes.LOAD_SUCCESS });
    }

}

function* updateStartPage() {
    const isEmailVerified = yield select((state) => state[moduleTypes.APP]?.isEmailVerified);
    console.log('isEmailVerified in updateStartPage:', isEmailVerified);
    const appState = JSON.parse(localStorage.getItem('persist:app'));
    const profile = yield select(profileState);
    const activeStep = yield select(signUpProcessState);
    const location = window.location;
    const appJ = JSON.parse(appState.app);

    if (!isEmailVerified) {
        navigate("/");
        return;
    }
    if (appJ?.isLoggedIn && !profile?.email) {
        logout();
        return;
    }
    if (appJ.isFromEmailSignUp) {
        navigate('/investor-qualification?step=investor-type');
        store.dispatch({ type: actionTypes.IS_FROM_EMAIL_SIGNUP, value: false });
    } else if (profile.firstTimeLogin === true && !activeStep) {
        // sometimes the response is slow here ..
        // I want to look up the profile and if it's empty ... this is a new account
        navigate('/investor-qualification?step=investor-type')
    } else if (/[/]checking/.test(location.pathname)) {
        if (!activeStep || activeStep === 'qualification-individual-one' || activeStep === 'qualification-individual-two' || activeStep === 'qualification-business-one' || activeStep === 'qualification-business-two' || activeStep === 'qualification-trust-one' || activeStep === 'qualification-trust-two'
            || activeStep === 'investor-type-ria'
        ) {
            navigate("/")
        } else {
            navigate("/invest-trade/")
        }
    }
}

export default init;
