import { takeLatest, put, call, select, delay } from 'redux-saga/effects'
import { actionTypes } from './type';
import { actionTypes as qualTypes } from '../InvestorQualification/type';
import { actionTypes as dialogActionTypes } from '../Dialog/type';
import { moduleTypes } from '../type';

import AccountService from '../../services/AccountService'
import { ACCOUNT_TYPE } from '../../Constants';
const accountService = new AccountService()

function* init() {
  // yield takeLatest(qualTypes.GET_ACCOUNT, getAccount);

  yield takeLatest(qualTypes.CREATE_ACCOUNT_TYPE, manageAccount);
  yield takeLatest(qualTypes.DELETE_ACCOUNT_TYPE, deleteAccount);

  yield takeLatest(qualTypes.UPDATE_INDIVIDUAL_STEP_ONE_FORM, updateAccountContactInfo);
  yield takeLatest(qualTypes.UPDATE_INDIVIDUAL_STEP_TWO_FORM, updateAccountVerification);

  yield takeLatest(qualTypes.UPDATE_ENTITY_STEP_ONE_FORM, updateAccountInfo);
  yield takeLatest(qualTypes.UPDATE_ENTITY_STEP_TWO_FORM, updateAccountContactInfo);
  yield takeLatest(qualTypes.UPDATE_ENTITY_STEP_THREE_FORM, updateAccountVerification);

  yield takeLatest(qualTypes.UPDATE_QUALIFICATION_ONE, updateAccountQualificationOne);
  yield takeLatest(qualTypes.UPDATE_QUALIFICATION_TWO, updateAccountQualificationTwo);

  yield takeLatest(qualTypes.UPDATE_QUESTION, updateAccountQuestion);
  yield takeLatest(qualTypes.UPDATE_NEW_ISSUES_CERTIFICATION, updateAccountNewIssuesCert);
  yield takeLatest(qualTypes.UPDATE_SPINNING_PROHIBITION_CERTIFICATION, updateAccountSpinningProhibitionCert);
}

const accountType = (state) => state[moduleTypes.INVESTOR_QUALIFICATION].accountType;
const newAccount = (state) => state[moduleTypes.ACCOUNTS].newAccount;

const individualContactInfo = (state) => state[moduleTypes.INVESTOR_QUALIFICATION].individualStepOneForm;
const individualVerificationInfo = (state) => state[moduleTypes.INVESTOR_QUALIFICATION].individualStepTwoForm;
const qualificationOneInfo = (state) => state[moduleTypes.INVESTOR_QUALIFICATION].qualificationOneForm;
const qualificationTwoInfo = (state) => state[moduleTypes.INVESTOR_QUALIFICATION].qualificationTwoForm;

const questionInfo = (state) => state[moduleTypes.INVESTOR_QUALIFICATION].questionForm;
const newIssuesInfo = (state) => state[moduleTypes.INVESTOR_QUALIFICATION].newIssuesForm;
const sCertInfo = (state) => state[moduleTypes.INVESTOR_QUALIFICATION].spinningProhibitionCertForm;

const entityAccountInfo = (state) => state[moduleTypes.INVESTOR_QUALIFICATION].entityStepOneForm;
const entityContactInfo = (state) => state[moduleTypes.INVESTOR_QUALIFICATION].entityStepTwoForm;
const entityVerificationInfo = (state) => state[moduleTypes.INVESTOR_QUALIFICATION].entityStepThreeForm;

function* manageAccount() {
  yield put({
    type: dialogActionTypes.SHOW_DIALOG,
    value: { type: 'dialog_save_loading', show: true, showSub: false, subType: null },
  });
  const type = yield select(accountType);

  try {
    const response = yield call(accountService.createAccount, { type });

    yield put({ type: actionTypes.UPDATE_ACCOUNTS_TYPE, value: response });
    yield put({ type: actionTypes.CREATE_ACCOUNT_SUCCEEDED, value: response });
  } catch (e) {
    console.error(e);
    yield put({ type: actionTypes.CREATE_ACCOUNT_FAILED, message: e.message });
  }
  yield put({
    type: dialogActionTypes.SHOW_DIALOG,
    value: { type: 'dialog_save_loading', show: false, showSub: false, subType: null },
  });
}

function* deleteAccount() {
  yield put({
    type: dialogActionTypes.SHOW_DIALOG,
    value: { type: 'dialog_save_loading', show: true, showSub: false, subType: null },
  });
  const account = yield select(newAccount);
  try {
    
    yield call(accountService.deleteAccount, account._id);

    yield put({ type: actionTypes.DELETE_NEW_ACCOUNT, value: { type: null } });
  } catch (e) {
    console.error(e);
    yield put({ type: actionTypes.DELETE_NEW_ACCOUNT_FAILED, message: e.message });
  }
  yield put({
    type: dialogActionTypes.SHOW_DIALOG,
    value: { type: 'dialog_save_loading', show: false, showSub: false, subType: null },
  });
}

function* updateAccountInfo() {
  yield put({ type: qualTypes.FORM_UPDATE_START });
  const account = yield select(newAccount);

  try {
    let data;
    switch (account.type) {
      default: return;
      case ACCOUNT_TYPE.BUSINESS:
      case ACCOUNT_TYPE.RIA:
        data = yield select(entityAccountInfo)
        yield call(accountService.updateEntityStepOne, account._id, data);
        break;
    }
    yield put({ type: actionTypes.UPDATE_ACCOUNT_INFO, value: data });
    yield put({ type: actionTypes.UPDATE_ACCOUNT_CONTACT_INFO, value: { entityName: data.entityName } });
  } catch (e) {
    console.error(e);
  }
  yield put({ type: qualTypes.FORM_UPDATE_SUCCESS });
}

function* updateAccountContactInfo() {
  yield put({
    type: dialogActionTypes.SHOW_DIALOG,
    value: { type: 'dialog_save_loading', show: true, showSub: false, subType: null },
  });
  yield put({ type: qualTypes.FORM_UPDATE_START });
  const account = yield select(newAccount);

  let data;
  let nextStep;
  switch (account.type) {
    case ACCOUNT_TYPE.INDIVIDUAL:
      data = yield select(individualContactInfo)
      yield call(accountService.updateIndividualStepOne, account._id, data);
      yield put({ type: actionTypes.UPDATE_ACCOUNT_CONTACT_INFO, value: data });
      nextStep = "qualification-individual-one";
      break;
    case ACCOUNT_TYPE.INDIVIDUAL_JOINT:
        data = yield select(individualContactInfo)
        yield call(accountService.updateIndividualStepOne, account._id, data);
        yield put({ type: actionTypes.UPDATE_ACCOUNT_CONTACT_INFO, value: data });
        nextStep = "qualification-individual-one";
        break;
    case ACCOUNT_TYPE.INDIVIDUAL_SD_IRA:
      data = yield select(individualContactInfo)
      yield call(accountService.updateIndividualStepOne, account._id, data);
      yield put({ type: actionTypes.UPDATE_ACCOUNT_CONTACT_INFO, value: data });
      nextStep = "qualification-individual-one";
      break;
    case ACCOUNT_TYPE.BUSINESS:
      data = yield select(entityContactInfo)
      yield call(accountService.updateEntityStepTwo, account._id, data);
      yield put({ type: actionTypes.UPDATE_ACCOUNT_CONTACT_INFO, value: data });
      nextStep = "qualification-business-one";
      break;
    case ACCOUNT_TYPE.TRUST:
      data = yield select(entityContactInfo)
      yield call(accountService.updateEntityStepTwo, account._id, data);
      yield put({ type: actionTypes.UPDATE_ACCOUNT_CONTACT_INFO, value: data });
      nextStep = "qualification-trust-one";
      break;
    case ACCOUNT_TYPE.RIA:
      data = yield select(entityContactInfo)
      yield call(accountService.updateEntityStepTwo, account._id, data);
      yield put({ type: actionTypes.UPDATE_ACCOUNT_CONTACT_INFO, value: data });
      nextStep = "ria-verification"
      break;
    default:
      break;
  }
  yield put({
    type: dialogActionTypes.SHOW_DIALOG,
    value: { type: 'dialog_save_loading', show: false, showSub: false, subType: null },
  });
  yield put({ type: qualTypes.FORM_UPDATE_SUCCESS });
  yield put({ type: qualTypes.UPDATE_ACTIVE_STEP, value: nextStep })
}

function* updateAccountQualificationOne() {
  yield put({ type: qualTypes.FORM_UPDATE_START });

  let data;
  data = yield select(qualificationOneInfo);

  yield put({ type: actionTypes.UPDATE_QUALIFICATION_ONE_INFO, value: data });
  yield put({ type: qualTypes.FORM_UPDATE_SUCCESS });
}

function* updateAccountQualificationTwo() {
  yield put({ type: qualTypes.FORM_UPDATE_START });

  let data;
  data = yield select(qualificationTwoInfo);

  yield put({ type: actionTypes.UPDATE_QUALIFICATION_TWO_INFO, value: data });
  yield put({ type: qualTypes.FORM_UPDATE_SUCCESS });
}

function* updateAccountQuestion() {
  yield put({ type: qualTypes.FORM_UPDATE_START });

  let data;
  data = yield select(questionInfo);

  yield put({ type: actionTypes.UPDATE_QUESTION_INFO, value: data });
  yield put({ type: qualTypes.FORM_UPDATE_SUCCESS });
}

function* updateAccountNewIssuesCert() {
  yield put({ type: qualTypes.FORM_UPDATE_START });

  let data;
  data = yield select(newIssuesInfo);

  yield put({ type: actionTypes.UPDATE_NEW_ISSUES_CERTIFICATION_INFO, value: data });
  yield put({ type: qualTypes.FORM_UPDATE_SUCCESS });
}

function* updateAccountSpinningProhibitionCert() {
  yield put({ type: qualTypes.FORM_UPDATE_START });

  let data;
  data = yield select(sCertInfo);

  yield put({ type: actionTypes.UPDATE_SPINNING_PROHIBITION_CERTIFICATION_INFO, value: data });
  yield put({ type: qualTypes.FORM_UPDATE_SUCCESS });
}

function* updateAccountVerification() {
  let account = yield select(newAccount);

  // yield put({
  //   type: dialogActionTypes.SHOW_DIALOG,
  //   value: { type: 'dialog_pending_verification', show: true, payment: {title: ` `} },
  // });

  try {
    let data, _data;
    switch (account?.type) {
      case ACCOUNT_TYPE.INDIVIDUAL:
      case ACCOUNT_TYPE.INDIVIDUAL_SD_IRA:
        data = yield select(individualVerificationInfo)
        _data = { ...data };
        delete _data['ssn']
        delete _data['termsChecked']
        delete _data['dateOfBirthSecond']
        delete _data['ssnSecond']
        if (!data.hasOwnProperty('approvalTemplate')) {
          _data.approvalTemplate = ""
        }
        if (data.hasOwnProperty('ssn')) {
          _data = {
            ..._data,
            secrets: {
              ssn: data.ssn
            }
          }
        }
        yield call(accountService.updateIndividualStepTwo, account._id, _data);
        break;
      case ACCOUNT_TYPE.INDIVIDUAL_JOINT:
        data = yield select(individualVerificationInfo)
        _data = { ...data };
        delete _data['ssn']
        delete _data['termsChecked']
        delete _data['ssnSecond']
        if (!data.hasOwnProperty('approvalTemplate')) {
          _data.approvalTemplate = ""
        }
        if (data.hasOwnProperty('ssn')) {
          _data = {
            ..._data,
            secrets: {
              ssn: data.ssn,
              ssnSecond: data.ssnSecond
            }
          }
        }
        yield call(accountService.updateIndividualStepTwo, account._id, _data);
        break;
      case ACCOUNT_TYPE.BUSINESS:
      case ACCOUNT_TYPE.RIA:
      case ACCOUNT_TYPE.TRUST:
        data = yield select(entityVerificationInfo)
        _data = { ...data };
        delete _data['ein']
        delete _data['ssn']
        delete _data['userSsn']
        delete _data['termsChecked']
        if (!data.hasOwnProperty('approvalTemplate')) {
          _data.approvalTemplate = ""
        }
        if (data.hasOwnProperty('ein')) {
          _data = {
            ..._data,
            secrets: {
              ein: data.ein,
              userSsn: data.userSsn
            }
          }
        }
        if (data.hasOwnProperty('ssn')) {
          _data = {
            ..._data,
            secrets: {
              ssn: data.ssn,
              userSsn: data.userSsn
            }
          }
        }
        yield call(accountService.updateEntityStepThree, account._id, _data);
        break;
      default:
        break;
    }

    yield call(accountService.updateVerificationSave, account._id, { 'verification': 'created' });

    yield put({
      type: dialogActionTypes.SHOW_DIALOG,
      value: { type: 'dialog_pending_verification', show: false, payment: null },
    });

    yield put({ type: qualTypes.FORM_UPDATE_SUCCESS });

    if (data.hasOwnProperty('ssn') || data.hasOwnProperty('ein')) {
      switch (account?.type) {
        case ACCOUNT_TYPE.INDIVIDUAL:
        case ACCOUNT_TYPE.INDIVIDUAL_SD_IRA:
        case ACCOUNT_TYPE.INDIVIDUAL_JOINT:
          yield put({ type: qualTypes.UPDATE_ACTIVE_STEP, value: "individual-verified" })
          break;
        case ACCOUNT_TYPE.BUSINESS:
          if (account.accountInfo.entityType === 'trust') {
            yield put({ type: qualTypes.UPDATE_ACTIVE_STEP, value: "trust-verified" })
          } else {
            yield put({ type: qualTypes.UPDATE_ACTIVE_STEP, value: "entity-verified" })
          }
          break;
        case ACCOUNT_TYPE.TRUST:
          console.log("trust-verified");
          yield put({ type: qualTypes.UPDATE_ACTIVE_STEP, value: "trust-verified" })
          break;
        case ACCOUNT_TYPE.RIA:
          yield put({ type: qualTypes.UPDATE_ACTIVE_STEP, value: "ria-verified" })
          break;
        default:
          break;
      }
    }
  } catch (e) {
    yield put({ type: qualTypes.FORM_UPDATE_SUCCESS });
  }
}

export default init;