const actionTypes = {
    LOAD_RIA_USERS: "load_ria_users",
    INIT_RIA_USERS_MGR: "init_ria_users_mgr",

    UPDATE_MY_RIA_ACCOUNT: "update_my_ria_account",
    UPDATE_RIA_USER: "update_ria_user",

    INVITE_RIA_USERS: "invite_ria_users",

    DELETE_RIA_USER: "delete_ria_user",
}

module.exports = {
    actionTypes
}