import { actionTypes } from "./type";

const initialState = {
    isLoggedIn: false,
    isFromEmailSignUp: false,
    isEmailVerified: false,
    accessToken: null,
    loading: false,
    /**
     * @typedef Profile
     * @type {object}
     * @property {string} firstName - first name of user.
     * @property {string} lastName - last name of user.
     * @property {string} email - email of user.
     */

    /** @type {Profile} */
    profile: {},

    /**
     * @typedef AccountSetupHero
     * @type {object}
     * @property {string} cmsContentUid - id for cms content.
     */

    /** @type {AccountSetupHero} */
    accountSetupHero: {
        cmsContentUid: null
    },

    /**
     * @typedef Invitation
     * @type {object}
     * @property {string} accoundId - master's ria account id
     * @property {int} isAdmin - invited as admin.
     * @property {string} email - invited email
     */

    /** @type {Invitation} */
    invitation: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_IS_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.value
            }
        case actionTypes.UPDATE_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.value
            }

        case actionTypes.UPDATE_AUTH_USER:
            return {
                ...state,
                authUser: action.value
            }

        case actionTypes.UPDATE_PROFILE:
            return {
                ...state,
                profile: action.value
            }

        case actionTypes.UPDATE_ACCOUNT_SETUP_HERO:
            return {
                ...state,
                accountSetupHero: action.value
            }

        case actionTypes.UPDATE_INVITATION_INFO:
            return {
                ...state,
                invitation: action.value
            }

        case actionTypes.IS_FROM_EMAIL_SIGNUP:
            return {
                ...state,
                isFromEmailSignUp: action.value
            }

        case actionTypes.IS_EMAIL_VERIFIED:
            return {
                ...state,
                isEmailVerified: action.value
            }
    
        case actionTypes.LOAD_ACCOUNTS_DATA:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.LOAD_START:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case actionTypes.UPDATE_LOGGING_IN:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}

export default reducer;