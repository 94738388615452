const ACCOUNT_TYPE = {
    INDIVIDUAL: 'individual',
    INDIVIDUAL_JOINT: 'joint',
    INDIVIDUAL_SD_IRA: 'sd-ira',
    BUSINESS: 'business',
    TRUST: 'trust',
    RIA: 'ria'
}

const ACCOUNT_SETUP_HERO_CMS_UID = {
    isQualificationStepOneComplete: 'is_qualification_step_one_complete',
    isQualificationStepTwoComplete: 'is_qualification_step_two_complete',
    hasUserSelectedAccountType: 'has_user_selected_account_type',
    hasUserSubmittedAccountContactInfo: 'has_user_submitted_account_contact_info',
    hasUserSubmittedAccountVerificationInfo: 'has_user_submitted_account_verification_info'
}

module.exports = {
    ACCOUNT_TYPE,

    ACCOUNT_SETUP_HERO_CMS_UID
}