import { actionTypes } from "./type";

const initialState = {
  myRiaAccount: null,
  
  riaUsers: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INIT_RIA_USERS_MGR:
      return {
        ...state,
        ...action.value,
      }

    case actionTypes.LOAD_RIA_USERS:
      return {
        ...state,
        riaUsers: action.value,
      }

    case actionTypes.UPDATE_MY_RIA_ACCOUNT:
      return {
        ...state,
        myRiaAccount: { ...state.myRiaAccount, ...action.value },
      }

    case actionTypes.UPDATE_RIA_USER:
      {
        const account = action.value;
        const riaUsers = state.riaUsers;
        console.log('update_ria_user - account', account);
        const index = riaUsers.findIndex((el => el._id === account._id));
        if (index >= 0) {
          riaUsers[index] = account;
        }

        return {
          ...state,
          riaUsers: [...riaUsers]
        }
      }

    case actionTypes.INVITE_RIA_USERS:
      {
        const invitedUsers = action.value;
        return {
          ...state,
          myRiaAccount: { ...state.myRiaAccount, invitedUsers: invitedUsers }
        }
      }

    case actionTypes.DELETE_RIA_USER:
      {
        const riaUserId = action.value.id;
        const riaUsers = state.riaUsers.filter((el) => el._id !== riaUserId);

        return {
          ...state,
          riaUsers
        }
      }

    default:
      return state;
  }
}

export default reducer;