import axios from 'axios'
import { store } from '../store'
import { moduleTypes } from '../store/type'
import { login } from '../utils/auth'

const API_URL = process.env.SERVER_API_ENDPOINT

export default class HttpCommon {
  constructor(subURL = '', auth = false) {
    let baseURL = API_URL
    if (subURL) {
      baseURL += `/${subURL}`
    }
    let offset = new Date().getTimezoneOffset();
    this.apiClient = axios.create({
      baseURL: baseURL,
      headers: {
        'Content-type': 'application/json',
        'timezone': -offset / 60
      },
    })

    if (auth) {
      this.attachAuthenticationToken()
      this.logoutInCaseOfInvalidToken()
    }
  }

  attachAuthenticationToken () {
    this.apiClient.interceptors.request.use(
      (request) => {
        const accessToken = store.getState()[moduleTypes.APP].accessToken
        request.headers.Authorization = accessToken
          ? `BEARER ${accessToken}`
          : ''
        return request
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  logoutInCaseOfInvalidToken () {
    this.apiClient.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response.status === 401) {
          login()
        }
        return Promise.reject(error)
      }
    )
  }

  get = (url = '') => {
    return this.apiClient.get(url)
  }

  post = (url = '', data = {}) => {
    return this.apiClient.post(url, data)
  }

  patch = (url = '', data = {}) => {
    return this.apiClient.patch(url, data)
  }

  delete = (url = '') => {
    return this.apiClient.delete(url)
  }
}
