const actionTypes = {
    UPDATE_ACCESS_TOKEN: 'update_access_token',
    UPDATE_IS_LOGGED_IN: 'is_logged_in',
    IS_SHOW_HERO_BANNER: 'is_show_hero_banner',
    IS_FROM_EMAIL_SIGNUP: 'is_from_email_signup',
    IS_EMAIL_VERIFIED: 'is_email_verified',

    GET_PROFILE: 'get_profile',
    LOAD_ACCOUNTS_DATA: 'load_accounts_data',
    UPDATE_PROFILE: 'set_profle',
    UPDATE_AUTH_USER: 'set_auth_user',    

    UPDATE_ACCOUNT_SETUP_HERO: 'update_account_setup_hero',
    UPDATE_INVITATION_INFO: 'update_invitation_info',
    UPDATE_LOGGING_IN: 'update_logging_in',
    LOAD_SUCCESS: 'load_success',
    LOAD_START: 'load_start',
}

module.exports = {
    actionTypes
}
