import { takeEvery, put, call, select } from 'redux-saga/effects'
import { navigate } from "gatsby";
import { createProfile } from '../App/saga';
import { actionTypes } from './type';
import { moduleTypes } from '../type';
import { ACCOUNT_TYPE } from '../../Constants'
import SignupService from '../../services/SignupService';
const signupService = new SignupService()

function* init() {
    yield takeEvery(actionTypes.UPDATE_ACTIVE_STEP, signUpProcessChain);
}

const signUpProcessState = (state) => state[moduleTypes.INVESTOR_QUALIFICATION].activeStep
// true is disqualified
const signUpDisqualificationOfAccreditedState = (state) => state[moduleTypes.INVESTOR_QUALIFICATION].disqualificationOfAccredited;
const signUpDisqualificationOfQualifiedState = (state) => state[moduleTypes.INVESTOR_QUALIFICATION].disqualificationOfQualified;

// Keeps the processors executing in the correct order
// so the database is in teh correct state before executing 
// the next step
function* signUpProcessChain() {
    yield call(createProfile);
    yield call(updateSignUpProcess);
}

function* updateSignUpProcess() {
    let step;
    try {
        step = yield select(signUpProcessState)
        yield call(signupService.updateSignUpProcess, {
            signUpProcessState: step,
            signUpDisqualificationOfAccreditedState: yield select(signUpDisqualificationOfAccreditedState),
            signUpDisqualificationOfQualifiedState: yield select(signUpDisqualificationOfQualifiedState),
        })
        yield call(updateAccountInformation);
    } catch (e) {
        console.log(e.message)
    } finally {
        const disqualificationOfAccredited = yield select(signUpDisqualificationOfAccreditedState);
        console.log('special check', disqualificationOfAccredited);
        if (!disqualificationOfAccredited &&
            step !== 'individual-verified' &&
            step !== 'trust-verified' &&
            step !== 'entity-verified' &&
            step !== 'ria-verified' &&
            step !== 'hide-hero')
            navigate(`/investor-qualification?step=${step}`);
    }
}

function* updateAccountInformation() {
    const step = yield select(signUpProcessState)

    switch (step) {
        case 'investor-type-joint':
            yield put({ type: actionTypes.UPDATE_ACCOUNT_TYPE, value: ACCOUNT_TYPE.INDIVIDUAL_JOINT });
            break;

        case 'investor-type-sd-ira':
            yield put({ type: actionTypes.UPDATE_ACCOUNT_TYPE, value: ACCOUNT_TYPE.INDIVIDUAL_SD_IRA });
            break;

        case 'investor-type-individual':
            yield put({ type: actionTypes.UPDATE_ACCOUNT_TYPE, value: ACCOUNT_TYPE.INDIVIDUAL });
            break;

        case 'investor-type-business':
            yield put({ type: actionTypes.UPDATE_ACCOUNT_TYPE, value: ACCOUNT_TYPE.BUSINESS });
            break;

        case 'investor-type-trust':
            yield put({ type: actionTypes.UPDATE_ACCOUNT_TYPE, value: ACCOUNT_TYPE.TRUST });
            break;

        case 'investor-type-ria':
            yield put({ type: actionTypes.UPDATE_ACCOUNT_TYPE, value: ACCOUNT_TYPE.RIA });
            break;

        default:
            break;
    }
}

export default init;