const actionTypes = {
    LOAD_INVESTER_QUALIFICATION: "load_invester_qualification",
    UPDATE_ACTIVE_STEP: "update_active_step",

    EDIT_ACCOUNT_STEP: "edit_account_step",

    UPDATE_ACCOUNT_TYPE: "update_account_type",
    CREATE_ACCOUNT_TYPE: "create_account_type",
    DELETE_ACCOUNT_TYPE: "delete_account_type",

    UPDATE_INDIVIDUAL_STEP_ONE_FORM: "update_individual_step_one_form",
    UPDATE_INDIVIDUAL_STEP_TWO_FORM: "update_individual_step_two_form",

    UPDATE_ENTITY_STEP_ONE_FORM: "update_entity_step_one_form",
    UPDATE_ENTITY_STEP_TWO_FORM: "update_entity_step_two_form",
    UPDATE_ENTITY_STEP_THREE_FORM: "update_entity_step_three_form",
    UPDATE_ENTITY_STEP_FOUR_FORM: "update_entity_step_four_form",

    UPDATE_RIA_STEP_ONE_FORM: "update_ria_step_one_form",
    UPDATE_RIA_STEP_TWO_FORM: "update_ria_step_two_form",
    UPDATE_RIA_STEP_THREE_FORM: "update_ria_step_three_form",

    UPDATE_QUALIFICATION_ONE: "update_qualification_one",
    UPDATE_QUALIFICATION_TWO: "update_qualification_two",

    UPDATE_QUESTION: "update_question",
    UPDATE_NEW_ISSUES_CERTIFICATION: "update_new_issues_certification",
    UPDATE_SPINNING_PROHIBITION_CERTIFICATION: "update_spinning_prohibition_certification",

    FORM_UPDATE_SUCCESS: "form_update_success",
    FORM_UPDATE_START: "form_update_start",

    START_VERIFY_ACCOUNT: 'start_verify_account',
    CONTINUE_VERIFY_ACCOUNT: 'continue_verify_account',

    EDIT_ACCOUNT: 'edit_account',
}

module.exports = {
    actionTypes
}