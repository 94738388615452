const actionTypes = {
    UPDATE_ACCOUNTS_TYPE: 'update_accounts_type',
    UPDATE_ACCOUNT_STRUCTURE: 'update_account_structure',
    UPDATE_ACCOUNT_INFO: 'update_account_info',
    UPDATE_ACCOUNT_CONTACT_INFO: 'update_account_contact_info',
    UPDATE_QUALIFICATION_ONE_INFO: 'update_qualification_one_info',
    UPDATE_QUALIFICATION_TWO_INFO: 'update_qualification_two_info',
    UPDATE_QUESTION_INFO: 'update_question_info',
    UPDATE_NEW_ISSUES_CERTIFICATION_INFO: 'update_new_issues_certification_info',
    UPDATE_SPINNING_PROHIBITION_CERTIFICATION_INFO: 'update_spinning_prohibition_certification_info',
    UPDATE_ACCOUNT_VERIFICATION: 'update_account_verification',
    DELETE_ACCOUNT: 'delete_account',

    CREATE_ACCOUNT_SUCCEEDED: "create_account_succeeded",
    CREATE_ACCOUNT_FAILED: "create_account_failed",
    RESET_NEW_ACCOUNT: "reset_new_account",
    DELETE_NEW_ACCOUNT: "delete_new_account",
    DELETE_NEW_ACCOUNT_FAILED: "delete_new_account_failed",
    DELETE_NEW_ACCOUNT_SUCCEEDED: "delete_new_account_succeeded",

    UPDATE_ACCOUNTS: "update_accounts",
    LOAD_ACCOUNTS: "load_accounts",

    SET_PAYMENT_METHOD: "set_payment_method",

    START_VERIFY_ACCOUNT: 'start_verify_account',
    CONTINUE_VERIFY_ACCOUNT: 'continue_verify_account',

    EDIT_ACCOUNT: 'edit_account'
}

module.exports = {
    actionTypes
}