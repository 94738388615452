import { actionTypes } from './type';
import { actionTypes as accountTypes } from '../Accounts/type';
import { ACCOUNT_TYPE } from '../../Constants'

const initialState = {
    activeStep: 'investor-type',
    disqualificationOfAccredited: undefined,
    disqualificationOfQualified: undefined,

    isFormUpdateLoading: false,

    accountType: '',
    individualStepOneForm: {
        legalFirstName: '',
        legalLastName: '',
        addressLineOne: '',
        secondFirstName: '',
        secondLastName: '',
        addressLineTwo: '',
        city: '',
        state: '',
        zipcode: '',
        phoneNo: '',
        extension: ''
    },
    individualStepTwoForm: {
        legalFirstName: '',
        legalLastName: '',
        dateOfBirth: '',
        secrets: {
            ssn: ''
        },
        approvalTemplate: '',
    },
    entityStepOneForm: {
        entityName: '',
        entityType: '',
        signersTitle: '',
    },
    entityStepTwoForm: {
        entityName: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        state: '',
        zipcode: '',
        phoneNo: '',
        extension: ''
    },
    entityStepThreeForm: {
        dateOfIncorporation: '',
        dateOfBirth:'',
        secrets: {
            ein: '',
            ssn: '',
            userSsn: '',
        },
        approvalTemplate: '',
        crd: '',
    },
    qualificationOneForm: {
        qualification1: [],
    },
    qualificationTwoForm: {
        qualification2: [],
    },
    questionForm: {
        question: [],
    },
    newIssuesForm: {
        newIssues: [],
    },
    spinningProhibitionCertForm: {
        sCert: [],
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_INVESTER_QUALIFICATION:
            return {
                ...state,
                ...action.value,
            }

        case actionTypes.UPDATE_ACTIVE_STEP:
            const disqual = action.qual !== undefined && !action.qual;
            if (action.value === "investor-type") {
                return {
                    ...state,
                    activeStep: action.value,
                    disqualificationOfQualified: disqual
                }
            } else if (action.value === "qualification-individual-two") {
                return {
                    ...state,
                    activeStep: disqual ? "qualification-individual-one" : action.value,
                    disqualificationOfAccredited: disqual
                }
            } else if (action.value === "qualification-business-two") {
                return {
                    ...state,
                    activeStep: disqual ? "qualification-business-one" : action.value,
                    disqualificationOfAccredited: disqual
                }
            } else if (action.value === "qualification-trust-two") {
                return {
                    ...state,
                    activeStep: disqual ? "qualification-trust-one" : action.value,
                    disqualificationOfAccredited: disqual
                }
            }
            return {
                ...state,
                activeStep: action.value,
            }

        case actionTypes.EDIT_ACCOUNT_STEP:
            return {
                ...state,
                editingStep: action.value,
            }

        case actionTypes.UPDATE_ACCOUNT_TYPE:
            return {
                ...state,
                accountType: action.value,
            }
        case actionTypes.CREATE_ACCOUNT_TYPE:
            return {
                ...state,
                accountType: action.value,
                isFormUpdateLoading: true
            }
        case accountTypes.CREATE_ACCOUNT_SUCCEEDED:
            return {
                ...state,
                isFormUpdateLoading: false,
                activeStep: state.accountType === ACCOUNT_TYPE.INDIVIDUAL ? 'investor-type-individual' : state.accountType === ACCOUNT_TYPE.INDIVIDUAL_JOINT ? 'investor-type-joint': state.accountType === ACCOUNT_TYPE.INDIVIDUAL_SD_IRA ? 'investor-type-sd-ira': state.accountType === ACCOUNT_TYPE.BUSINESS ? 'investor-type-business' : state.accountType === ACCOUNT_TYPE.TRUST ? 'investor-type-trust' : 'investor-type-ria'
            }

        case accountTypes.CREATE_ACCOUNT_FAILED:
            return {
                ...state,
                isFormUpdateLoading: false
            }

        case actionTypes.UPDATE_INDIVIDUAL_STEP_ONE_FORM:
            return {
                ...state,
                individualStepOneForm: { ...action.value }
            }

        case actionTypes.UPDATE_INDIVIDUAL_STEP_TWO_FORM:
            return {
                ...state,
                individualStepTwoForm: { ...action.value }
            }

        case actionTypes.UPDATE_ENTITY_STEP_ONE_FORM:
            return {
                ...state,
                entityStepOneForm: { ...action.value }
            }

        case actionTypes.UPDATE_ENTITY_STEP_TWO_FORM:
            return {
                ...state,
                entityStepTwoForm: { ...action.value }
            }

        case actionTypes.UPDATE_ENTITY_STEP_THREE_FORM:
            return {
                ...state,
                entityStepThreeForm: { ...action.value }
            }
        case actionTypes.UPDATE_QUALIFICATION_ONE:
            return {
                ...state,
                qualificationOneForm: [ ...action.value ]
            }
        case actionTypes.UPDATE_QUALIFICATION_TWO:
            return {
                ...state,
                qualificationTwoForm: { ...action.value }
            }
        case actionTypes.UPDATE_QUESTION:
            return {
                ...state,
                questionForm: { ...action.value }
            }
        case actionTypes.UPDATE_NEW_ISSUES_CERTIFICATION:
            console.log("state", action.value)
            return {
                ...state,
                newIssuesForm: { ...action.value }
            }
        case actionTypes.UPDATE_SPINNING_PROHIBITION_CERTIFICATION:
            return {
                ...state,
                spinningProhibitionCertForm: { ...action.value }
            }
        case actionTypes.FORM_UPDATE_SUCCESS:
            return {
                ...state,
                isFormUpdateLoading: false,
            }
        case actionTypes.FORM_UPDATE_START:
            return {
                ...state,
                isFormUpdateLoading: true,
            }

        case actionTypes.START_VERIFY_ACCOUNT:
            const response = action.value;
            let activeStep;
            if (response?.type === "individual") {
                activeStep = 'individual-verification'
            } else if (response?.type === "ria") {
                activeStep = 'ria-verification'
            } else {
                if (response?.entityType === 'trust') {
                    activeStep = 'trust-verification'
                } else {
                    activeStep = 'entity-verification'
                }
            }
            return {
                ...state,
                activeStep,
                accountType: response?.type,
            }
        default:
            return state
    }
}

export default reducer