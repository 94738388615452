const moduleTypes = {
    APP: 'app',
    INVESTOR_QUALIFICATION: 'investor_qualification',
    ACCOUNTS: 'accounts',
    ACTIVITIES: 'activities',
    DIALOG: 'dialog',
    RIA_USERS_MGR: 'riaUsersMgr',
}

module.exports = {
    moduleTypes
}
