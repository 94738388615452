import { actionTypes } from "./type";

const initialState = {
  newAccount: undefined, // Account being setup
  parentAccounts: [],

  // payment
  paymentAccounts: [],
  isPaymentInitiation: false,
  isItemAccess: false,
  linkSuccess: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_ACCOUNTS:
      return {
        ...state,
        ...action.value,
      }
    case actionTypes.UPDATE_ACCOUNTS_TYPE: {
      // STEP 1 - individual, entity, RIA
      const { newAccount = {} } = state;
      const { type, ...rest } = action.value;
      return { ...state, newAccount: { ...newAccount, type, ...rest } };
    }

    case actionTypes.DELETE_NEW_ACCOUNT: {
      return { ...state, newAccount: undefined };
    }

    case actionTypes.UPDATE_ACCOUNT_INFO: {
      // STEP 2/1 - entity, RIA
      const { newAccount = {} } = state;
      const accountInfo = action.value;
      return { ...state, newAccount: { ...newAccount, accountInfo } };
    }

    case actionTypes.UPDATE_ACCOUNT_CONTACT_INFO: {
      // STEP 1/2/2 - individual, entity, RIA
      const { newAccount = {} } = state;
      const contactInfo = action.value;
      return { ...state, newAccount: { ...newAccount, contactInfo } };
    }

    case actionTypes.UPDATE_QUALIFICATION_ONE_INFO: {
      const { newAccount = {} } = state;
      const qualification1 = action.value;
      return { ...state, newAccount: { ...newAccount, qualification1 } };
    }

    case actionTypes.UPDATE_QUALIFICATION_TWO_INFO: {
      const { newAccount = {} } = state;
      const qualification2 = action.value;
      return { ...state, newAccount: { ...newAccount, qualification2 } };
    }

    case actionTypes.UPDATE_QUESTION_INFO: {
      const { newAccount = {} } = state;
      const question = action.value;
      return { ...state, newAccount: { ...newAccount, question } };
    }

    case actionTypes.UPDATE_NEW_ISSUES_CERTIFICATION_INFO: {
      const { newAccount = {} } = state;
      const newIssues = action.value;
      return { ...state, newAccount: { ...newAccount, newIssues } };
    }

    case actionTypes.UPDATE_SPINNING_PROHIBITION_CERTIFICATION_INFO: {
      const { newAccount = {} } = state;
      const sCert = action.value;
      return { ...state, newAccount: { ...newAccount, sCert } };
    }

    case actionTypes.UPDATE_ACCOUNT_VERIFICATION:
    {
      const { _id, data } = action.value;
      const accounts = state.parentAccounts;
      const index = accounts.findIndex((item => item._id === _id));
      if (index >= 0) {
        accounts[index] = {...accounts[index], ...data}
      }

      return {
        ...state,
        parentAccounts: [...accounts]
      }
    }

    case actionTypes.DELETE_ACCOUNT:
    {
      const { _id } = action.value;
      const accounts = state.parentAccounts.filter((item => item._id !== _id));
      return {
        ...state,
        parentAccounts: [...accounts]
      }
    }

    case actionTypes.CREATE_ACCOUNT_SUCCEEDED:
      const { type, ...rest } = action.value;
      return { ...state, newAccount: { contactInfo: {}, accountInfo: {}, type, ...rest } };

    case actionTypes.RESET_NEW_ACCOUNT:
      return { ...state, newAccount: { ...state.newAccount, contactInfo: {}, accountInfo: {} } };

    case actionTypes.UPDATE_ACCOUNTS: 
    {
      const { _id, value } = action;
      const accounts = state.parentAccounts;
      const index = accounts.findIndex((item => item._id === _id));
      switch (index) {
        default:
          accounts[index] = value;
          break;
        case -1:
          accounts.push(value);
          break;
      }

      return {
        ...state,
        parentAccounts: [...accounts]
      }
    }

    case actionTypes.SET_PAYMENT_METHOD:
      return {
        ...state,
        ...action.value,
      }

    case actionTypes.START_VERIFY_ACCOUNT:
    case actionTypes.CONTINUE_VERIFY_ACCOUNT:
    case actionTypes.EDIT_ACCOUNT:
      const response = action.value;
      let _newAccount = {
        type: response?.type,
        _id: response?._id,
        userId: response?.userId,
        qualification1: response?.qualification1,
        qualification2: response?.qualification2,
        question: response?.question,
        sCert: response?.sCert,
        newIssues: response?.newIssues,
        contactInfo: {
          legalFirstName: response?.legalFirstName,
          legalLastName: response?.legalLastName,
          addressLineOne: response?.addressLineOne,
          addressLineTwo: response?.addressLineTwo,
          city: response?.city,
          state: response?.state,
          zipcode: response?.zipcode,
          phoneNo: response?.phoneNo,
          extension: response?.extension,
          mailChecked: response?.mailChecked,
          investorEmail: response?.investorEmail,
          mailAddressLineOne: response?.mailAddressLineOne,
          mailAddressLineTwo: response?.mailAddressLineTwo,
          mailCity: response?.mailCity,
          mailState: response?.mailState,
          mailZipcode: response?.mailZipcode,
          duplicateChecked: response?.duplicateChecked,
          duplicateFirstName: response?.duplicateFirstName,
          duplicateLastName: response?.duplicateLastName,
          duplicateEmail: response?.duplicateEmail,
          duplicateAddressLineOne: response?.duplicateAddressLineOne,
          duplicateAddressLineTwo: response?.duplicateAddressLineTwo,
          duplicateCity: response?.duplicateCity,
          duplicateState: response?.duplicateState,
          duplicateZipcode: response?.duplicateZipcode,
        },
      }
      if (response?.type === "individual" || response?.type === "sd-ira") {
        _newAccount = {
          ..._newAccount,
          contactInfo: {
            ..._newAccount.contactInfo,
            qualification: response?.qualification,
            investorEmail: response?.investorEmail,
          }
        }
      } else if (response?.type === "joint") {
        _newAccount = {
          ..._newAccount,
          contactInfo: {
            ..._newAccount.contactInfo,
            secondFirstName: response?.secondFirstName,
            secondLastName: response?.secondLastName,
            secondEmail: response?.secondEmail,
            secondPhoneNo: response?.secondPhoneNo,
            qualification: response?.qualification,
            investorEmail: response?.investorEmail,
          }
        }
      } else if (response?.type === "ria") {
        _newAccount = {
          ..._newAccount,
          contactInfo: {
            ..._newAccount.contactInfo,
            entityName: response?.entityName,
            riaCompany: response?.riaCompany,
          },
          accountInfo: {
            entityName: response?.entityName,
            entityType: response?.entityType,
            signersTitle: response?.signersTitle,
          }
        }
      } else {
        _newAccount = {
          ..._newAccount,
          contactInfo: {
            ..._newAccount.contactInfo,
            entityName: response?.entityName,
            signersTitle: response?.signersTitle,
            signersFirstName: response?.signersFirstName,
            signersLastName: response?.signersLastName,
            signersEmail: response?.signersEmail,
            qualification: response?.qualification,
          },
          
          accountInfo: {
            entityName: response?.entityName,
            entityType: response?.entityType,
            taxIdentificationType: response?.taxIdentificationType,
          }
        }
      }
      return {
        ...state,
        newAccount: _newAccount,
      }

    default:
      return state;
  }
}

export default reducer;