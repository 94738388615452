import HttpCommon from './HttpCommon'

import { ErrorWrapper, ResponseWrapper } from './util'
export default class AccountService {
    constructor() {
        this.http = new HttpCommon('account', true)
    }

    createAccount = async (data = {}) => {
        try {
            const response = await this.http.post('', data)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    deleteAccount = async (id, data = {}) => {
        try {
            const response = await this.http.delete(`/${id}`, data)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    updateIndividualSave = async (id, data = {}) => {
        try {
            const response = await this.http.patch(`/individual-save/${id}`, data)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    updateIndividualStepOne = async (id, data = {}) => {
        try {
            const response = await this.http.patch(`/individual-step-one/${id}`, data)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    updateIndividualStepTwo = async (id, data = {}) => {
        try {
            const response = await this.http.patch(`/individual-step-two/${id}`, data)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    updateEntityStepOne = async (id, data = {}) => {
        try {
            const response = await this.http.patch(`/entity-step-one/${id}`, data)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    updateEntityStepTwo = async (id, data = {}) => {
        try {
            const response = await this.http.patch(`/entity-step-two/${id}`, data)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    updateEntityStepThree = async (id, data = {}) => {
        try {
            const response = await this.http.patch(`/entity-step-three/${id}`, data)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    updateEntitySave = async (id, data = {}) => {
        try {
            const response = await this.http.patch(`/entity-save/${id}`, data)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    updateQualificationOneSave = async (id, data = {}) => {
        try {
            const response = await this.http.patch(`/qualification-one-save/${id}`, data)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    updateQualificationTwoSave = async (id, data = {}) => {
        try {
            const response = await this.http.patch(`/qualification-two-save/${id}`, data)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    updateQuestionSave = async (id, data = {}) => {
        try {
            const response = await this.http.patch(`/question-save/${id}`, data)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    updateNewIssuesCertificationSave = async (id, data = {}) => {
        try {
            const response = await this.http.patch(`/new-issues-save/${id}`, data)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    updateSpinningCertificationSave = async (id, data = {}) => {
        try {
            const response = await this.http.patch(`/spinning-cert-save/${id}`, data)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    updateVerificationSave = async (id, data = {}) => {
        try {
            const response = await this.http.patch(`/verification-save/${id}`, data)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    setRiaAdmin = async (id) => {
        try {
            const response = await this.http.patch(`/set-ria-admin/${id}`, {})
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    setInvitedUserAdmin = async (email, isAdmin) => {
        try {
            const response = await this.http.patch(`/set-invited-user-admin`, { email, isAdmin })
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    setRiaJoinOnlyInvite = async (joinOnlyInvite) => {
        try {
            const response = await this.http.patch(`/set-ria-join-only-invite`, {joinOnlyInvite})
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    deleteRiaUser = async (accoundId) => {
        try {
            const response = await this.http.patch(`/delete-ria-user/${accoundId}`, {})
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    deleteInvitedUser = async (email) => {
        try {
            const response = await this.http.patch(`/delete-invited-user`, {email})
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    inviteRiaUsers = async (emails, isAdmin) => {
        try {
            const response = await this.http.post(`/invite-ria-users`, { emails, isAdmin })
            return new ResponseWrapper(response).data
        } catch (error) {
            console.log("inviteRiaUsers", error);
            new ErrorWrapper(error)
        }
    }

    getAccount = async (id, payment = true) => {
        try {
            const response = await this.http.get(`/${id}?payment=${payment}`)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    getAllAccounts = async (id, payment = true) => {
        try {
            const response = await this.http.get(`/user/${id}?payment=${payment}`)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    getVerifiedRIABusinesses = async (keyword) => {
        try {
            const response = await this.http.get(`/verified-ria-businesses?keyword=${keyword}`)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    getRiaUsers = async (accountId) => {
        try {
            const response = await this.http.get(`/ria-users/${accountId}`)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    checkSsnUsable = async (accountId, ssn, firstName, lastName) => {
        try {
            const maskedSsn = `${ssn.slice(0, 2)}*****${ssn.slice(-2)}`
            let params = `maskedSsn=${maskedSsn}&isEin=0`;
            if (firstName && lastName) {
                params = `${params}&firstName=${firstName}&lastName=${lastName}`;
            }
            const response = await this.http.get(`/check-ssn-usable/${accountId}?${params}`)
            
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    checkEinUsable = async (accountId, ein, companyName) => {
        try {
            const maskedEin = `${ein.slice(0, 2)}*****${ein.slice(-2)}`
            let params = `maskedEin=${maskedEin}&isEin=1`;
            if (companyName) {
                params = `${params}&companyName=${companyName}`
            }
            const response = await this.http.get(`/check-ssn-usable/${accountId}?${params}`)
            
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

}