import HttpCommon from './HttpCommon'

import { ErrorWrapper, ResponseWrapper } from './util'
export default class SignupService {
    constructor() {
        this.http = new HttpCommon('signup', true)
    }

    updateSignUpProcess = async (data = {}) => {
        try {
            const response = await this.http.patch('/record-process', data)
            return new ResponseWrapper(response).data
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    registerEarlyAccessGate = async (data = {}) => {
        try {
            const response = await this.http.post('/early-access-gate', data)
            return new ResponseWrapper(response).data
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }
}