const actionTypes = {
  SHOW_DIALOG: "show_dialog",
  SET_OPAL: "set_opal",
  SET_ACCOUNT: "set_account",
  SET_PAYMENT_ACCOUNTS: "set_payment_accounts",
  SET_PAYMENT_ITEM: "set_payment_item",
  SET_LINK_TOKEN: "set_link_token",
}

module.exports = {
  actionTypes
}
