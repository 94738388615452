import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga';
import { combineReducers } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import alertReducer from "./reducers/alert";
import storage from 'redux-persist/lib/storage';

import app from './App'
import appSaga from './App/saga'
import { moduleTypes } from './type'

import investorQualification from './InvestorQualification';
import investorQualificationSaga from './InvestorQualification/saga';

import accounts from './Accounts';
import accountsSaga from './Accounts/saga';

import activities from './Activities';
import activitySaga from './Activities/saga';

import dialog from './Dialog';
import dialogSaga from './Dialog/saga';

import riaUsersMgr from './RiaUsersMgr';
import riaUsersMgrSaga from './RiaUsersMgr/saga';


const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: persistReducer(
    {
      key: moduleTypes.APP,
      storage,
      whitelist: [moduleTypes.APP]
    }, combineReducers({
      [moduleTypes.APP]: app,
      [moduleTypes.INVESTOR_QUALIFICATION]: investorQualification,
      [moduleTypes.ACCOUNTS]: accounts,
      [moduleTypes.ACTIVITIES]: activities,
      [moduleTypes.DIALOG]: dialog,
      [moduleTypes.RIA_USERS_MGR]: riaUsersMgr,
      alert: alertReducer,
    })
  ),
  middleware: [sagaMiddleware],
});

persistStore(store)

sagaMiddleware.run(accountsSaga)
sagaMiddleware.run(activitySaga)
sagaMiddleware.run(dialogSaga)
sagaMiddleware.run(investorQualificationSaga)
sagaMiddleware.run(appSaga)
sagaMiddleware.run(riaUsersMgrSaga)
