import { createSlice } from "@reduxjs/toolkit";

export const NOT_VERIFIED_ADD_NEW_ACCOUNT = "not_verified_add_new_account";
export const NOT_VERIFIED_OPAL_BUY = "not_verified_opal_buy";
export const NOT_VERIFIED_DEEP_OPAL_INFO = "deep_opal_info_not_verified_banner_2";
//
export const INITIAL_QUALIFICATION = "initial_qualification";
export const SUBSEQUENT_LOG_INS = "subsequent_log_ins";
export const NON_ACCREDITED_BANNERS = "non-accredited_banners";

const initialState = {
  type: "",
  show: false,
  subType: "",
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showAlert(state, action) {
      state.type = action.payload.type;
      state.subType = action.payload.subType;
      state.show = true;
    },
    hideAlert(state, action) {
      state.type = "";
      state.subType = "";
      state.show = false;
    },
  },
});

export const { showAlert, hideAlert } = alertSlice.actions;

export default alertSlice.reducer;
