import { actionTypes } from "./type";

const initialState = {
  show: false,
  opal: null,
  type: null,
  account: null,
  showSub: false,
  subType: null,
  cost: 0,
  paymentAccounts: null,
  paymentItem: null,
  payment: null,
  fromBack: false,
  fromPage: null,
  changed: false,
  loading: false,
  sentAccounts: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_DIALOG: {
      return { ...state, ...action.value };
    }
    case actionTypes.SET_OPAL: {
      return { ...state, opal: action.value };
    }
    case actionTypes.SET_ACCOUNT: {
      return { ...state, account: action.value };
    }
    case actionTypes.SET_PAYMENT_ACCOUNTS: {
      return { ...state, paymentAccounts: action.value };
    }
    case actionTypes.SET_PAYMENT_ITEM: {
      return { ...state, paymentItem: action.value };
    }
    case actionTypes.SET_LINK_TOKEN: {
      return { ...state, account: { ...state.account, linkToken: action.value}}
    }
    default:
      return state;
  }
}

export default reducer;
